var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"main_button_wrapper main_bg_color",class:{
        'main_shadow':                      _vm.shadow && !_vm.dark,
        'main_button_dark_shadow':          _vm.shadow && _vm.dark,
        'main_button_dark dark_bg_color':   _vm.dark,
        'sub_bg_color':                     _vm.subColor,
        'dark_shadow':                      _vm.subColor && _vm.shadow,
        'main_button_slim':                 _vm.slim,
        'main_button_loading':              _vm.loading,
        'main_button_disabled':             _vm.disabled,
        'main_button_readonly':             _vm.readonly,
    },style:(_vm.cssVars),on:{"click":function($event){return _vm.submit()}}},[(_vm.text)?_c('span',[(_vm.loading)?_c('span',[_vm._v(" טוען... ")]):_c('span',[_vm._v(" "+_vm._s(_vm.text)+" ")])]):_vm._e(),_vm._t("content")],2)}
var staticRenderFns = []

export { render, staticRenderFns }