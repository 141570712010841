<template>
    <v-flex 
        d-flex 
        justify-center 
        class="massage_card_wrapper"
        :class="{
            'message_card_top': top,
            'message_card_bottom': bottom,
            'message_card_center': center,
            'message_card_persistent': persistent,
            'message_card_small': small,
        }"
    >
        <v-flex
            mx-auto 
            d-flex 
            justify-center
            v-click-outside="{ handler: closeMessageFromOutside }"
            class="message_card_content px-5 dark_shadow white_bg_color h100 text-center"
            
        >
            <div>
                <div :class="`message_card_type_${type}`" class="message_card_top_box" :style="`background-color: ${color}`">
                    <img loading="lazy" :src="images[type]" alt="">
                </div>
                
                <br>
                
                <div class="message_card_details px-5">
                    <h2 class="main_text_color">
                        {{title}}
                    </h2>

                    <p v-html="message">
                    </p>
                </div>

                <v-flex mx-auto>
                    <main-button
                        ref="messageCardButton"
                        class="message_card_button" 
                        shadow
                        :text="buttonText"
                        @submit="closeMessage"
                    />
                </v-flex>
            </div>
        </v-flex>
    </v-flex>
</template>

<script>
import MainButton from '../Buttons/MainButton.vue'

export default {
    components: { MainButton },

    props: {
        // Positions
        top: {
            type: Boolean,
        },
        
        center: {
            type: Boolean,
            default: true
        },
        
        bottom: {
            type: Boolean,
        },

        // Sizes
        small: {
            type: Boolean,
        },

        normal: {
            type: Boolean,
            default: true
        },
        
        // Options
        persistent: {
            type: Boolean,
            default: true
        },

        type: {
            type: String,
            default: 'success',
            validator(value) {
                return ['error', 'success', 'warning', 'info'].includes(value);
            }
        },

        // Content
        title: {
            type: String,
            required: true,
        },

        message: {
            type: String,
            required: true
        },

        buttonText: {
            type: String,
            default: 'המשך'
        },
    },

    data() {
        return {
            images: {
                success: require('../../../public/assets/images/messages/success.svg'),
                info: require('../../../public/assets/images/messages/info.svg'),
                error: require('../../../public/assets/images/messages/error.svg'),
                warning: require('../../../public/assets/images/messages/warning.svg'),
            },

            colors: {
                success: '#23B920',
                info: '#16588f',
                error: '#FF2E00',
                warning: '#FFC700',
            },
        }
    },

    computed: {
        color() {
            return this.colors[this.type];
        }
    },

    mounted() {
        this.$refs.messageCardButton.$el.focus()
    },

    methods: {
        closeMessageFromOutside() {
            if(!this.persistent) {
                this.closeMessage()
            }
        },

        closeMessage() {
            this.$emit('closeMessage');
        }
    }
}
</script>

<style scoped lang="scss">

    .massage_card_wrapper {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 101;
    }
    
    .message_card_persistent {
        position: fixed;
    }

    .message_card_center {
        align-items: center;
    }

    .message_card_content {
        position: fixed;
        z-index: 101;
        border-radius: 12px;
        min-height: 250px;
        max-height: 350px;
        width: 350px;
        max-width: 80%;
        background-color: #fff;

        .message_card_details {
            position: absolute;
            width: 100%;
            right: 0;
        }
    }

    .message_card_button {
        position: relative;
        bottom: -115px;
        width: 140%;
        right: -20%;
    }

    h2 {
        font-size: 2em;
    }

    .message_card_top {
        align-items: flex-start;
        margin-top: 30px;
    }

    .message_card_small {
        
        .message_card_content {
            min-height: 150px;
            max-height: 275px;
        }
    }

    .message_card_top_box {
        border-radius: 0 0 8px 8px;
        padding: 10px;
    }

    .message_card_type_info img {
        position: relative;
        left: 10px;
    }

</style>