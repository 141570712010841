<template>
  <svg
    viewBox="0 0 488 321"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M326.843 0.0440674L275.467 275.568L487.781 102.204L367.077 117.064L326.843 0.0440674Z"
      fill="#d4b36f"
    />
    <path
      d="M400.503 196.935L356.555 234.084L447.546 298.476L400.503 196.935Z"
      fill="#d4b36f"
    />
    <path
      d="M295.956 105.678L289.125 112.146H144.224C114.022 112.146 64.4034 155.627 69.4371 202.341C73.4641 239.713 104.673 253.128 119.775 255.164H202.472L209.304 215.637H136.314L131.28 209.528L141.708 157.423L148.539 151.315L280.855 149.518L286.248 157.423L257.844 314.815L251.372 320.205C220.929 320.804 150.121 321.643 110.426 320.205C70.7316 318.768 41.1521 296.608 31.3242 285.708C-20.8111 227.495 0.31497 134.066 38.2917 100.288C87.1911 56.7946 115.956 46.7462 160.045 46.746H300.63L304.945 52.8548L295.956 105.678Z"
      fill="#d4b36f"
    />
  </svg>
</template>

<script>
export default {

};
</script>

<style scoped>
  svg {
    height: 100%;
    width: 100%;
  }
</style>
